@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600&display=swap');
.ce-block__content,
.ce-toolbar__content {
 max-width: 1000px;  /* example value, adjust for your own use case */
}

::-webkit-scrollbar {
    width: 3px; /* Adjust the width as needed */
  }

  /* Track background */
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Adjust the color as needed */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color as needed */
    border-radius: 4px; /* Adjust the border radius as needed */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color as needed */
  }